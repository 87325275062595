body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.copyRight {
  color: #939393;
  display: flex;
  align-items: flex-end;
  padding: 0px;
}

.socialFrame {
  width: 32px;
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  flex-grow: 0;
}

.ironSocialFrame {
  width: 32px;
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  flex-grow: 0;
}

.socialIcon {
  background-color: #3b3b3b;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  flex: none;
  border-radius: 22px;
  justify-content: center;
  flex-grow: 0;
}

.socialsMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 50px;
  gap: 50px;
}

.footerMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 54px;
  gap: 54px;
}

.siteFooter {
  color: #ffffff;
  background-color: #202020;
  height: 200px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  margin-top: auto;
}
.footerMenuAll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

body {
  background-color: #282828;
  color: "#ffffff";
}

a {
  text-decoration: none;
}

.tile {
  background-color: #272727;
  flex: 1 1;
}

.topHeaderLink {
  color: #ffffff;
  background: none;
  stroke: none;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.headerMenu {
  display: flex;
  flex: none;
  flex-direction: row;
  order: 1;
  align-items: center;
  padding: 0px;
  grid-gap: 40px;
  gap: 40px;
  color: #ffffff;
}

.headerLogo {
  color: #ffffff;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 80px;
  display: flex;
  flex-direction: row;
  order: 0;
  align-items: center;
  justify-content: center;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
}
.logoIcon {
  background-blend-mode: soft-light;
  height: 60px;
  position: relative;
  object-position: center;
}

.headerContainer {
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px 80px;
  flex-direction: row;
  color: #ffffff;
  height: 80px;
  background-color: #202020;
  margin-bottom: auto;
}

.backgroundContainer {
  position: relative;
  background-image: url("/assets/bigShape.png");
  width: 753px;
  height: 664px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.appIntroductionImage {
  position: absolute;
  width: 270px;
  height: 571px;
}

.introductionContainer {
  display: flex;
  flex-direction: "row";
  flex-wrap: wrap;
  padding: 30;
  grid-gap: 100;
  gap: 100;
  min-height: calc(100vh - 300px);
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  margin-top: 10%;
  top: px;
  right: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

.hamburgerIcon {
  font-size: 20px;
}

.ironclubIntroductionHeading {
  color: #ffffff;
  font-size: 38px;
  font-family: Inter;
  word-wrap: break-word;
  font-weight: 700;
  margin-bottom: 16px;
}
.ironclubDescriptionParagraph {
  color: #cdcdcd;
  font-size: 20px;
  font-weight: 300;
  font-family: Inter;
  word-wrap: break-word;
  max-width: 576px;
}
.bottomSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iosAndroidParagraph {
  color: #ffffff;
  font-size: 38px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.linkContainer {
  width: 210px;
  height: 20px;
  padding: 20px;
  background: #373737;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  grid-gap: 0;
  gap: 0;
  display: inline-flex;
}

.downloadBox {
  color: #ffffff;
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.buildParagraph {
  color: #b6b6b6;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.iosContainer {
  margin-right: 50px;
}

.androidContainer {
  margin-right: 50px;
}

.indexContainer {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.textFrameContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.iconAndLinkContainer {
  display: flex;
  flex-direction: row;
}

.storesIconContainer {
  margin: auto;
}

.storesIconStyle {
  flex-shrink: 0;
  padding-right: 10px;
}

.headerParagraph {
  font-weight: 700;
  padding: 16px;
}

.returnPolicyContainer {
  padding: 30;
  grid-gap: 100;
  gap: 100;
  min-height: calc(100vh - 300px);
}

.deleteAccountRequestContainer {
  grid-gap: 100;
  gap: 100;
  min-height: calc(100vh - 300px);
  background-color: white;
}

@media (max-width: 420px) {
  .indexContainer {
    flex-wrap: nowrap;
  }

  .introductionContainer {
    display: flex;
    flex-direction: "column";
    padding: 30;
    grid-gap: 100;
    gap: 100;
  }

  .backgroundContainer {
    position: relative;
    background-image: url("/assets/bigShapeMobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .appIntroductionImage {
    position: absolute;
    width: 270px;
    height: 571px;
  }

  .headerMenu {
    display: none;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    order: 1;
    align-items: center;
    padding: 0px;
    grid-gap: 40px;
    gap: 40px;
    color: #ffffff;
  }

  .hamburger {
    display: block;
  }

  .ironclubIntroductionHeading {
    color: #ffffff;
    font-size: 32px;
    font-family: Inter;
    word-wrap: break-word;
    font-weight: 700;
    margin: auto;
    margin-bottom: 16px;
  }

  .ironclubDescriptionParagraph {
    color: #cdcdcd;
    font-size: 14px;
    font-weight: 274;
    font-family: Inter;
    word-wrap: break-word;
    margin: auto;
    max-width: 300px;
  }

  .bottomSectionContainer {
    display: flex;
    flex-direction: column;
  }

  .iosAndroidParagraph {
    color: #ffffff;
    font-size: 32px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .iosContainer {
    margin: auto;
  }

  .androidContainer {
    margin: auto;
  }

  .downloadBox {
    color: #202020;
    font-size: 16px;
    font-family: Inter;
    font-weight: 590;
    word-wrap: break-word;
  }

  .buildParagraph {
    color: #b6b6b6;
    font-size: 12px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
  }

  .linkContainer {
    width: 210px;
    height: 20px;
    padding: 20px;
    background: #ffcc05;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
    gap: 0;
    display: inline-flex;
  }

  .footerMenuAll {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .siteFooter {
    color: #ffffff;
    background-color: #202020;
    height: 300px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }

  .footerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



